.topDiv{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
  
  }
  .topLeftDiv{
     
      display: flex;
      width: 60%;
      align-items: center;
      justify-content: space-around;
  }
  .topLeft1{
      display: flex;
      align-items: center;
  }
  .smLogo{
      width: 50px;
      height: 50px;
  }
  .topLeft2{
    width: 70%;
  }
  .topSearchBar{
    width: 100%;
      height: 27px;
      padding-left: 27px;
      color: aqua;
      outline: none;
      border-radius:5px ;
      border-color: gray;
  }
  .topSearchBar:focus{
    border: 2px solid violet;
  }
  .topRightDiv{
      display: flex;
      width: 20%;
      justify-content: space-evenly;
      align-items: center;
  }
  .bellLogo{
      width: 30px;
      height: 30px;
  }
.dot{
   
   margin-top: -20px;
   color: rgb(13, 206, 206);
}
.connected{
    color: rgb(7, 201, 201);
    background-color: rgb(199, 235, 235);
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
}
.KG{
    color: white;
    background-color: rgb(0, 0, 82);
    padding: 5px;
    border-radius: 15px;
}
.SecondDiv{
    display: flex;
   

}