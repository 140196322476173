body{
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}
.fa{
  position: absolute;
  text-align: center;
  padding:8px;
  color:grey;
  font-weight: 100;
  font-size: small;
}
.material-icons{
  margin-right: 5px;
}
/* .ma{
  position: absolute;
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
